/**
 * 策划面对面API
 * */
import { gameplusApi } from '@/util/http';

export function getActCenterBannerList() {
  return gameplusApi.request({
    url: '/community.GCQaLiveSrv/GetActCenterBannerList',
    data: {
    },
    options: {
      errorHandler: false,
    },
  });
}

export function getActivityList({ pageSize = 20, nextActId = 0 }) {
  return gameplusApi.request({
    url: '/community.GCActivityConfSvr/GetActcenterList',
    data: {
      page_size: pageSize,
      next_act_id: nextActId,
    },
    options: {
      errorHandler: false,
    },
  });
}


export default {
  getActCenterBannerList,
  getActivityList,
};
