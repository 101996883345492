<template>
  <div
    v-if="actImg"
    class="banner-container"
  >
    <img
      :src="actImg"
      @click="handleActivityJump"
      class="banner-image"
      alt="banner"
    >
  </div>
</template>

<script>
import {
  openView,
} from '@/jsbridge';
import {
  getActivityList,
} from '@/api/activity';
import launchApp from '@/util/launchApp';
import { checkEnv } from '@/util/browser-env';

export default {
  name: 'PostActivity',
  data() {
    return {
      env: checkEnv(),
      actImg: '',
      actUrl: '',
    };
  },
  mounted() {
    this.loadFirstActivity();
  },
  methods: {
    loadFirstActivity() {
      getActivityList({ pageSize: 20, nextActId: 0 }).then((result) => {
        if (result.ret !== 0) {
          // load fail
          return;
        }
        let activity;
        const now = Date.now() / 1000;
        for (activity of result.actList) {
          let canBeShow = true;
          if (activity.startTime && parseInt(activity.startTime, 10) > now) {
            canBeShow = false;
          }
          if (activity.endTime && parseInt(activity.endTime, 10) < now) {
            canBeShow = false;
          }
          if (canBeShow) {
            this.actImg = activity.actIcon;
            this.actUrl = activity.actUrl;
            break;
          }
        }
      });
    },
    handleActivityJump() {
      if (this.env.isApp) {
        openView({
          options: this.actUrl,
        });
      } else {
        launchApp({
          scheme: this.actUrl,
        });
      }
    },
  },
};
</script>

<style scoped>
.banner-container {
  margin: 16px 18px 16px 18px;
}
.banner-image {
  width: 100%;
  border-radius: 6px;
}
</style>
